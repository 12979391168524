<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12">
        <page-header>{{ $t('global_goals.glob_dev_goals') }}</page-header>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center pb-16">
      <v-col cols="12">
        <page-header-subtitle>{{ $t('global_goals.subtitle') }}</page-header-subtitle>
      </v-col>
    </v-row>

    <v-row class="pb-16" v-for="(goal, i) in goals" :key="i">
      <v-col cols="12" md="8" class="d-flex">
        <a :href="'https://sdgs.un.org/goals/goal' + goal" target="_blank">
          <v-img class="goal-img mr-5 elevation-2" :lazy-src="require('./assets/g' + goal + ' - lazy.png')" :src="require('./assets/g' + goal + '.png')"></v-img>
        </a>

        <div class="d-flex flex-column">
          <span class="text-h5">{{$t('global_goals.g' + goal + '_title')}}</span>
          <span>{{$t('global_goals.g' + goal + '_subtitle')}}</span>
          <span class="pt-4 text-subtitle-2">{{$t('global_goals.what_we_do')}}</span>
          <span>{{$t('global_goals.g' + goal + '_what_we_do')}}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageHeaderSubtitle from '@/components/PageHeaderSubtitle';

export default {
  name: 'GlobalGoals',
  components: {
    PageHeader,
    PageHeaderSubtitle,
  },
  data: function () {
    return {
      goals: [5, 8, 9, 13, 17],
    }
  }
}
</script>

<style scoped>
.goal-img {
  border-radius: 8px;
  width: 200px;
  height: 200px;
  max-width: 20vmin;
  max-height: 20vmin;
}
</style>
